import { TextField as MuiTextField, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as Sentry from '@sentry/browser'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { logger } from 'spartacus/services/LoggerService'
import { AuthenticationType } from 'spartacus/services/TransportLayer'
import Alert from 'spartacus/components/Alert'
import Button, { Variant } from 'spartacus/components/Button'
import Typography from 'spartacus/components/Typography'
import Constants from 'spartacus/constants'
import useStores from 'spartacus/hooks/useStores'
import Colors from 'spartacus/styles/colors'
import { isFacebookApp } from 'spartacus/utils'
import * as yup from 'yup'
import * as S from './style'

export type EmailInputAnalyticsLocation = 'top' | 'middle' | 'bottom' | 'footer'

export interface Props {
  type: 'authorize' | 'public-dashboard'
  buttonLabel?: string
  buttonVariant?: Variant
  analyticsLocation: EmailInputAnalyticsLocation
  id: string
  lightOnDark?: boolean
  populatePublicEmail?: boolean
  onSuccess?: () => void
}

interface Fields {
  email: string
}

const useInputStyles = makeStyles<Theme, { lightOnDark: boolean }>({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: (props): string => (props.lightOnDark ? Colors.UI.White : 'transparent'),
    },
    '& .MuiInputLabel-formControl': {
      top: -2,
    },
    '& .MuiOutlinedInput-input': {
      padding: '15px 14px 16px',
    },
  },
})

const textFieldProps = {
  'aria-label': 'scan email',
}

const EmailScanNowInput = observer(
  ({
    id,
    type,
    analyticsLocation,
    buttonVariant = 'primary',
    buttonLabel = 'Scan Now',
    lightOnDark = false,
    populatePublicEmail = false,
    onSuccess,
  }: Props): JSX.Element => {
    const inputClasses = useInputStyles({ lightOnDark })
    const { sessionStore, analyticsStore } = useStores()
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [hasGeneralError, setHasGeneralError] = useState(false)
    const [facebookApp, setFacebookApp] = useState(false)
    const reCaptchaRef = useRef<ReCAPTCHA>(null)
    const router = useRouter()
    const analyticsCategory =
      type === 'authorize' ? 'email authorize' : 'email view public dashboard'
    const useCaptcha =
      (Constants.ENVIRONMENT === 'staging' || Constants.ENVIRONMENT === 'production') &&
      !facebookApp
    const fields: Fields = {
      email: populatePublicEmail ? sessionStore.publicEmail || '' : '',
    }

    const handleAuth = async (email: string, authentication: AuthenticationType): Promise<void> => {
      if (onSuccess) {
        onSuccess()
      }

      if (authentication === 'password') {
        logger.debug(
          `[Email Scan Now Input] user has password, redirecting to log-in. email: ${email}`,
        )
        router.replace(`/log-in?email=${encodeURIComponent(email)}`)
      }

      if (authentication === 'email') {
        sessionStore.publicEmail = email
        logger.debug('[Email Scan Now Input] legacy user, pushing to create-password')
        router.push('/create-password')
      }
    }

    const handleSuccess = async ({ email }: Fields): Promise<void> => {
      try {
        analyticsStore.event({
          category: analyticsCategory,
          action: analyticsLocation,
          label: 'submit success',
        })

        // Check to see if this email address is already a subscriber
        const {
          registered,
          mailing_list_subscription,
          authentication,
        } = await sessionStore.getUserRegistrationStatus(email)

        if (type === 'authorize') {
          await handleAuth(email, authentication)
        } else {
          if (!mailing_list_subscription) {
            analyticsStore.event({
              category: analyticsCategory,
              action: analyticsLocation,
              label: 'lead',
            })
          }

          if (registered) {
            await handleAuth(email, authentication)
          } else {
            sessionStore.publicEmail = email
            logger.debug(
              '[Email Scan Now Input] non registered user, redirecting to public dashboard',
            )
            router.push('/dashboard')
          }
        }
      } catch (e) {
        setHasGeneralError(true)
        Sentry.captureException(e)
      }
    }

    const formik = useFormik<Fields>({
      initialValues: fields,
      validationSchema: yup.object({
        email: yup
          .string()
          .email('Please enter a valid email address')
          .required('Your email address is required'),
      }),
      onSubmit: (values): void => {
        setHasGeneralError(false)

        if (useCaptcha && reCaptchaRef.current) {
          reCaptchaRef.current.execute()
        }

        if (!useCaptcha) {
          handleSuccess(values)
        }
      },
    })

    useEffect(() => {
      if (formik.isSubmitting && !hasSubmitted) {
        setHasSubmitted(true)
        setHasGeneralError(false)
      }
    }, [formik.isSubmitting])

    useEffect(() => {
      setFacebookApp(isFacebookApp())
    }, [])

    const handleCaptchaChange = async (value: string | null): Promise<void> => {
      if (reCaptchaRef.current && useCaptcha) {
        reCaptchaRef.current.reset()
      }

      if (value || !useCaptcha) {
        handleSuccess(formik.values)
      }
    }

    return (
      <S.Container onSubmit={formik.handleSubmit}>
        {hasGeneralError && (
          <S.ErrorContainer>
            <Alert>Something went wrong. Please try again.</Alert>
          </S.ErrorContainer>
        )}

        <S.TextInput>
          <MuiTextField
            classes={inputClasses}
            id={id}
            type="email"
            autoComplete="email"
            inputProps={textFieldProps}
            placeholder="Enter Email"
            variant="outlined"
            {...formik.getFieldProps('email')}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            margin="none"
            fullWidth
            disabled={sessionStore.isAuthorizing}
          />
        </S.TextInput>

        <S.FinePrint>
          <Typography
            color={
              lightOnDark
                ? Colors.util.fade(Colors.Brand.Primary5, 0.3)
                : Colors.util.fade(Colors.Type.Primary, 0.7)
            }
            kind="TSL - Legal"
          >
            By clicking &ldquo;{buttonLabel}&rdquo;, I accept the{' '}
            <Link href="/terms-and-conditions">
              <a>Terms and Conditions</a>
            </Link>
            . This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </Typography>
        </S.FinePrint>

        {useCaptcha && (
          <ReCAPTCHA
            ref={reCaptchaRef}
            size="invisible"
            sitekey={Constants.RECAPTCHA_CLIENT_SITE_KEY}
            onChange={handleCaptchaChange}
          />
        )}
        <S.SubmitButton>
          <Button
            type="submit"
            variant={buttonVariant}
            disabled={!formik.dirty}
            loading={sessionStore.isAuthorizing}
          >
            {buttonLabel}
          </Button>
        </S.SubmitButton>
      </S.Container>
    )
  },
)

export default EmailScanNowInput
