import React from 'react'
import { NextSeo as NextSEO } from 'next-seo'

interface Props {
  title: string
  description?: string
  url?: string
  noindex?: boolean
}

const SEO = ({ title, description, url, noindex = false }: Props): JSX.Element => (
  <NextSEO
    title={title}
    description={description}
    noindex={noindex}
    openGraph={{
      title: `${title} | Spartacus`,
      description,
      // TODO: populate this by environment
      url: url ? `https://spartacus.com${url}` : undefined,
    }}
  />
)

export default SEO
