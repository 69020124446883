import styled from 'styled-components'
import dimensions from 'spartacus/styles/dimensions'

export const Container = styled.form`
  display: grid;
  grid-template-areas:
    'error'
    'text-input'
    'fine-print'
    'submit';
  justify-items: stretch;
  align-items: start;

  @media ${dimensions.xs} {
    margin-bottom: 1.5rem;
  }

  @media ${dimensions.md} {
    grid-template-areas:
      'error error'
      'text-input submit'
      'fine-print .';

    margin-bottom: 0;
  }
`

export const ErrorContainer = styled.div`
  grid-area: error;
  margin-bottom: 1vh;
`

export const TextInput = styled.div`
  grid-area: text-input;
  margin-bottom: 1vh;

  @media ${dimensions.md} {
    margin-right: ${dimensions.nColumns(0.25)};
  }
`

export const FinePrint = styled.div`
  grid-area: fine-print;
  margin-bottom: 2vh;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  @media ${dimensions.md} {
    margin: 0 ${dimensions.nColumns(0.25)} 0 0;
  }
`

export const SubmitButton = styled.div`
  grid-area: submit;
  display: flex;
  justify-content: center;
`
